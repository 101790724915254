import { Component, OnInit } from "@angular/core";

@Component({
  selector: "circleink-contact-detail",
  templateUrl: "./contact-detail.component.html",
  styleUrls: ["./contact-detail.component.scss"]
})
export class ContactDetailComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
