import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "circleink-project-slider",
  templateUrl: "./project-slider.component.html",
  styleUrls: ["./project-slider.component.scss"]
})
export class ProjectSliderComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    margin: 0,
    nav: true,
    smartSpeed: 700,
    autoplay: true,
    autoplaySpeed: 5000,
    navText: [
      '<span class="fa fa-angle-left"></span>',
      '<span class="fa fa-angle-right"></span>'
    ],
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      778: {
        items: 2,
        margin: 20
      },
      1024: {
        items: 3,
        margin: 20
      },
      1200: {
        items: 3
      }
    }
  };
  constructor() {}

  ngOnInit() {}
}
