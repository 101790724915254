import { Component, OnInit } from "@angular/core";

@Component({
  selector: "circleink-header-one",
  templateUrl: "./header-one.component.html",
  styleUrls: ["./header-one.component.scss"]
})
export class HeaderOneComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
