import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "circleink-home-pigment-block",
  templateUrl: "./home-pigment-block.component.html",
  styleUrls: ["./home-pigment-block.component.scss"]
})
export class HomePigmentBlockComponent implements OnInit {
  @Input() imageURL = "";
  @Input() title = "";
  @Input() subTitle = "";
  @Input() routeLink = "";
  constructor(private route: Router) {}

  ngOnInit() {}

  viewMore() {
    this.route.navigate([this.routeLink]);
  }
}
