import { Component, OnInit } from "@angular/core";
import { isNullOrUndefined } from "util";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "circleink-lets-talk",
  templateUrl: "./lets-talk.component.html",
  styleUrls: ["./lets-talk.component.scss"]
})
export class LetsTalkComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {}

  scrolltoId(strName) {
    if (!isNullOrUndefined(document.querySelector("#" + strName))) {
      document
        .querySelector("#" + strName)
        .scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      this.router.navigate(["home"], { fragment: strName });
    }
  }
}
