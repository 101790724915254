import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "circleink-project-box",
  templateUrl: "./project-box.component.html",
  styleUrls: ["./project-box.component.scss"]
})
export class ProjectBoxComponent implements OnInit {
  @Input() imageURL = "";
  @Input() title = "";
  @Input() subTitle = "";
  @Input() routeLink = "";
  @Input() isProject = true;
  @Input() buttonText = "View More";
  constructor(private route: Router) {}

  ngOnInit() {}

  viewMore() {
    this.route.navigate([this.routeLink]);
  }
}
