import { Component, OnInit } from "@angular/core";

@Component({
  selector: "circleink-header-three",
  templateUrl: "./header-three.component.html",
  styleUrls: ["./header-three.component.scss"]
})
export class HeaderThreeComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
