import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { HostListener } from "@angular/core";
import { environment } from "../../environments/environment";

@Component({
  selector: "circleink-slider",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class SliderComponent implements OnInit {
  imageUrls: (string | any)[] = [
    {
      url: "assets/images/banners/2.png",
      caption: `<h1>Welcome to Automatic Components</h1>
      <p>You expect the best.
      We deliver more.<br>We are manufacturer of wide range of pigment pastes and construction chemicals</p>`
    },
    {
      url: "assets/images/banners/3.png"
    },
    {
      url: "assets/images/banners/4.png"
    },
    {
      url: "assets/images/banners/5.png"
    },
    {
      url: "assets/images/banners/6.png"
    },
    {
      url: "assets/images/banners/7.png"
    },
    {
      url: "assets/images/banners/8.png"
    }
  ];
  height = "500px";
  minHeight: "500px";
  arrowSize = "20px";
  showArrows = true;
  disableSwiping = false;
  autoPlay = true;
  autoPlayInterval = 3333;
  stopAutoPlayOnSlide = true;
  debug = false;
  backgroundSize = "cover";
  backgroundPosition = "center center";
  backgroundRepeat = "no-repeat";
  showDots = true;
  dotColor = "#FFF";
  showCaptions = true;
  captionColor = "#FFF";
  captionBackground = "rgba(0, 0, 0, .35)";
  lazyLoad = false;
  hideOnNoSlides = false;
  width = "100%";
  fullscreen = false;
  enableZoom = false;
  enablePan = false;
  noLoop = false;

  screenHeight: number;
  screenWidth: number;
  constructor() {}

  ngOnInit() {
    this.getScreenSize();
  }
  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.height =
      (window.innerHeight - window.innerHeight / 4.7).toString() + "px";
  }
}
