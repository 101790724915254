import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ContactDetailComponent } from "./contact-detail/contact-detail.component";
import { HomeComponent } from "./home/home.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "home", component: HomeComponent },
  {
    path: "about",
    loadChildren: () => import("./about/about.module").then(m => m.AboutModule)
  },
  {
    path: "products",
    loadChildren: () =>
      import("./projects/projects.module").then(m => m.ProjectsModule)
  },
  {
    path: "ProductDetail",
    loadChildren: () =>
      import("./aqua-rock/aqua-rock.module").then(m => m.AquaRockModule)
  },
  {
    path: "PigmentProductDetail",
    loadChildren: () =>
      import("./pigment/pigment.module").then(m => m.PigmentModule)
  },
  {
    path: "appointment",
    loadChildren: () =>
      import("./appointment/appointment.module").then(m => m.AppointmentModule)
  },
  {
    path: "privacy",
    loadChildren: () =>
      import("./privacy-policy/privacy-policy.module").then(
        m => m.PrivacyPolicyModule
      )
  },
  {
    path: "disclaimer",
    loadChildren: () =>
      import("./disclaimer/disclaimer.module").then(m => m.DisclaimerModule)
  },
  { path: "contact", component: ContactDetailComponent },
  {
    path: "terms",
    loadChildren: () =>
      import("./termsand-condition/termsand-condition.module").then(
        m => m.TermsandConditionModule
      )
  },
  { path: "**", component: HomeComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      onSameUrlNavigation: "reload",
      scrollPositionRestoration: "top"
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
