import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SendEmailService } from "../send-email.service";

@Component({
  selector: "circleink-contactus",
  templateUrl: "./contactus.component.html",
  styleUrls: ["./contactus.component.scss"]
})
export class ContactusComponent implements OnInit {
  tileForm: FormGroup;
  constructor(
    private contactformTile: SendEmailService,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private router: Router
  ) {}

  ngOnInit() {
    this.initializeForm();
  }

  sendEmail(): void {
    if (this.tileForm.valid && this.tileForm.dirty) {
      this.tileForm.disable();
      this.contactformTile
        .sendEmail(this.tileForm.value)
        .subscribe(response => {
          if (response) {
            this.tileForm.reset();
            console.error(response);
          } else {
            this.toastrService.success(
              "Thanks for sending email. We will contact you soon!",
              "Success"
            );
            this.tileForm.reset();
            this.tileForm.disable();
          }
        });
    } else {
      this.toastrService.error(
        "Please add all required details in the Contact Form!",
        "Failed"
      );
    }
  }

  private initializeForm(): void {
    this.tileForm = this.fb.group({
      name: ["", Validators.required],
      email: ["", [Validators.email, Validators.required]],
      city: [""],
      number: [""],
      messsage: ["", Validators.required],
      toMail: ["sales@autocomp.co.in"]
    });
  }
}
