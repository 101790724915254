import { Component, OnInit } from "@angular/core";

@Component({
  selector: "circleink-counter",
  templateUrl: "./counter.component.html",
  styleUrls: ["./counter.component.scss"]
})
export class CounterComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
