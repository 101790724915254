import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { FooterComponent } from "./footer/footer.component";
import { HomeComponent } from "./home/home.component";
import { ContactusComponent } from "./contactus/contactus.component";
import { LetsTalkComponent } from "./lets-talk/lets-talk.component";
import { SliderComponent } from "./slider/slider.component";
import { ContactDetailComponent } from "./contact-detail/contact-detail.component";
import { VideoComponent } from "./video/video.component";
import { SharedModuleModule } from "./shared-module/shared-module.module";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ForUserComponent } from "./for-user/for-user.component";
import { PigmentsComponent } from "./pigments/pigments.component";
import { HomePigmentBlockComponent } from "./home-pigment-block/home-pigment-block.component";
import { HeaderOneComponent } from "./header-one/header-one.component";
import { VideoBlockComponent } from "./video-block/video-block.component";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToastrModule } from "ngx-toastr";

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HomeComponent,
    ContactusComponent,
    LetsTalkComponent,
    SliderComponent,
    ContactDetailComponent,
    VideoComponent,
    ForUserComponent,
    PigmentsComponent,
    HomePigmentBlockComponent,
    HeaderOneComponent,
    VideoBlockComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModuleModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
