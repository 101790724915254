import { Input } from "@angular/core";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "circleink-pigments",
  templateUrl: "./pigments.component.html",
  styleUrls: ["./pigments.component.scss"]
})
export class PigmentsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
