import { Component, OnInit } from "@angular/core";

@Component({
  selector: "circleink-contact-panel",
  templateUrl: "./contact-panel.component.html",
  styleUrls: ["./contact-panel.component.scss"]
})
export class ContactPanelComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
