import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "circleink-page-banner",
  templateUrl: "./page-banner.component.html",
  styleUrls: ["./page-banner.component.scss"]
})
export class PageBannerComponent implements OnInit {
  @Input() title = "";
  @Input() subTitle = "";
  @Input() bgImage = "";
  constructor() {}

  ngOnInit() {}
}
