import { Component, OnInit } from "@angular/core";
import { isNullOrUndefined } from "util";

@Component({
  selector: "circleink-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  scrolltoId(strName) {
    if (!isNullOrUndefined(document.querySelector("#" + strName))) {
      document
        .querySelector("#" + strName)
        .scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
}
