import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import {
  MatButtonModule,
  MatMenuModule,
  MatIconModule,
  MatCardModule,
  MatSidenavModule,
  MatFormFieldModule,
  MatInputModule,
  MatTooltipModule,
  MatToolbarModule,
  MatTreeModule,
  MatDialogModule,
  MatTableModule,
  MatListModule,
  MatProgressSpinnerModule,
  MatPaginatorModule,
  MatSortModule,
  MatGridListModule,
  MatSelectModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatRadioModule
} from "@angular/material";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatCardModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatToolbarModule,
    MatDialogModule,
    MatTreeModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatSelectModule,
    MatListModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatRadioModule
  ],
  exports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatCardModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatToolbarModule,
    MatDialogModule,
    MatTreeModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatSelectModule,
    MatListModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatRadioModule
  ]
})
export class MaterialModule {}
