import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "circleink-owl-corousel",
  templateUrl: "./owl-corousel.component.html",
  styleUrls: ["./owl-corousel.component.scss"]
})
export class OwlCorouselComponent implements OnInit {
  @Input() imageURL = "";
  @Input() title = "";
  @Input() subTitle = "";
  @Input() routeLink = "";
  @Input() isProject = true;
  @Input() buttonText = "View More";
  @Input() homePage = false;
  constructor() {}

  ngOnInit() {}
}
