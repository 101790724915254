import { Component, OnInit } from "@angular/core";

@Component({
  selector: "circleink-for-user",
  templateUrl: "./for-user.component.html",
  styleUrls: ["./for-user.component.scss"]
})
export class ForUserComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
