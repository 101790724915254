import { Component } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "circleink-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  currentUrl: any;
  constructor(private router: Router, meta: Meta) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        console.log("this.currentUrl" + this.currentUrl);
      }
    });
  }
}
