import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProjectSliderComponent } from "../project-slider/project-slider.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbCarouselModule } from "@ng-bootstrap/ng-bootstrap";
import { CarouselModule } from "ngx-owl-carousel-o";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { HeaderThreeComponent } from "../header-three/header-three.component";
import { PageBannerComponent } from "../page-banner/page-banner.component";
import { ProjectsLogoSliderComponent } from "../projects-logo-slider/projects-logo-slider.component";
import { CounterComponent } from "../counter/counter.component";
import { ProjectBoxComponent } from "../project-box/project-box.component";
import { SlideshowModule } from "ng-simple-slideshow";
import { OwlCorouselComponent } from "../owl-corousel/owl-corousel.component";
import { ContactPanelComponent } from "../contact-panel/contact-panel.component";
import { MaterialModule } from "../material/material.module";
import { ToastrModule } from "ngx-toastr";

@NgModule({
  declarations: [
    ProjectSliderComponent,
    HeaderThreeComponent,
    PageBannerComponent,
    ProjectsLogoSliderComponent,
    CounterComponent,
    ProjectBoxComponent,
    OwlCorouselComponent,
    ContactPanelComponent
  ],
  imports: [
    CommonModule,
    NgbCarouselModule,
    CarouselModule,
    LoadingBarModule,
    LoadingBarRouterModule,
    SlideshowModule,
    MaterialModule
  ],
  exports: [
    CommonModule,
    NgbCarouselModule,
    CarouselModule,
    LoadingBarModule,
    LoadingBarRouterModule,
    SlideshowModule,
    MaterialModule,
    ProjectSliderComponent,
    HeaderThreeComponent,
    PageBannerComponent,
    ProjectsLogoSliderComponent,
    CounterComponent,
    ProjectBoxComponent,
    OwlCorouselComponent,
    ContactPanelComponent
  ]
})
export class SharedModuleModule {}
